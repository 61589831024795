import { createSaleOrder, getSaleOrders, getSaleOrder, updateSaleOrder, archiveSaleOrder } from 'gox-sdk'

export const saleOrderModule = {
	state: () => ({
		saleOrders: [],
		currentSaleOrder: [],
	}),

	mutations: {
		setSaleOrders(state, { saleOrders }) {
			state.saleOrders = saleOrders
		},
		setCurrentSaleOrder(state, { saleOrder }) {
			state.currentSaleOrder = saleOrder
		},
	},

	actions: {
		async getSaleOrders({ commit }, { merchantId }) {
			const result = await getSaleOrders({ merchantId })
			commit('setSaleOrders', { saleOrders: result })
		},

		async getSaleOrder({ commit }, { id }) {
			const result = await getSaleOrder({ id })
			commit('setCurrentSaleOrder', { saleOrder: result })
		},

		async archiveSaleOrder({ commit }, { id }) {
			const result = await archiveSaleOrder({ id })
			commit('setCurrentSaleOrder', { saleOrder: result })
		},

		updateSaleOrder: async (store, { id, saleOrder }) => {
			await updateSaleOrder({ id, saleOrder })
		},

		createSaleOrder: async (store, { saleOrder }) => {
			await createSaleOrder({ saleOrder })
		},
	},

	getters: {
		saleOrders: state => {
			return state.saleOrders
		},

		currentSaleOrder: state => {
			return state.currentSaleOrder
		},
	},
}
