import { createSupplier, getSuppliers, getSupplier, updateSupplier, archiveSupplier } from 'gox-sdk'

export const supplierModule = {
    state: () => ({
        suppliers: [],
        currentSupplier: [],
    }),

    mutations: {
        setSuppliers(state, { suppliers }) {
            state.suppliers = suppliers
        },
        setCurrentSupplier(state, { supplier }) {
            state.currentSupplier = supplier
        },
    },

    actions: {
        async getSuppliers({ commit }, { merchantId }) {
            const result = await getSuppliers({ merchantId })
            commit('setSuppliers', { suppliers: result })
        },

        async getSupplier({ commit }, { id }) {
            const result = await getSupplier({ id })
            commit('setCurrentSupplier', { supplier: result })
        },

        async archiveSupplier({ commit }, { id }) {
            const result = await archiveSupplier({ id })
            commit('setCurrentSupplier', { supplier: result })
        },

        updateSupplier: async (store, { id, supplier }) => {
            await updateSupplier({ id, supplier })
        },

        createSupplier: async (store, { supplier }) => {
            await createSupplier({ supplier })
        },
    },

    getters: {
        suppliers: state => {
            return state.suppliers
        },
        supplierByMerchantId: state => merchantId => {
            return state.suppliers.filter(supplier => supplier.merchantId === merchantId)
        },
        supplierName: state => id => {
            return state.suppliers.find(supplier => supplier.id === id)
        },
        currentSupplier: state => {
            return state.currentSupplier
        },
    },
}
