import { createLocation, getLocations, getLocation, updateLocation, archiveLocation } from 'gox-sdk'

export const locationModule = {
    state: () => ({
        locations: [],
        currentLocation: [],
    }),

    mutations: {
        setLocations(state, { locations }) {
            state.locations = locations
        },
        setCurrentLocation(state, { location }) {
            state.currentLocation = location
        },
    },

    actions: {
        async getLocations({ commit }, { merchantId }) {
            const result = await getLocations({ merchantId })
            commit('setLocations', { locations: result })
        },

        async getLocation({ commit }, { id }) {
            const result = await getLocation({ id })
            commit('setCurrentLocation', { location: result })
        },

        async archiveLocation({ commit }, { id }) {
            const result = await archiveLocation({ id })
            commit('setCurrentLocation', { location: result })
        },

        updateLocation: async (store, { id, location }) => {
            await updateLocation({ id, location })
        },

        createLocation: async (store, { location }) => {
            await createLocation({ location })
        },
    },

    getters: {
        locations: state => {
            return state.locations
        },
        locationByMerchantId: state => merchantId => {
            return state.locations.filter(location => location.merchantId === merchantId)
        },
        locationName: state => id => {
            return state.locations.find(location => location.id === id)
        },
        currentLocation: state => {
            return state.currentLocation
        },
    },
}
