import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/index.css'
// import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-select/dist/vue-select.css'

import NButton from '@/components/Button'
Vue.component('n-button', NButton)
import NBadge from '@/components/Badge'
Vue.component('n-badge', NBadge)
import NInput from '@/components/Input'
Vue.component('n-input', NInput)
// import NSelect from '@/components/Select'
import NSelect from 'vue-select'
Vue.component('n-select', NSelect)
import NMultiselect from 'vue-multiselect'
Vue.component('n-multiselect', NMultiselect)
import Draggable from 'vuedraggable'
Vue.component('n-draggable', Draggable)
import NTable from '@/components/table/Table'
Vue.component('n-table', NTable)
import NDataTable from '@/components/table/DataTable'
Vue.component('n-data-table', NDataTable)

Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
