import { createCustomer, getCustomers, getCustomer, updateCustomer, archiveCustomer } from 'gox-sdk'

export const customerModule = {
	state: () => ({
		customers: [],
		currentCustomer: [],
	}),

	mutations: {
		setCustomers(state, { customers }) {
			state.customers = customers
		},
		setCurrentCustomer(state, { customer }) {
			state.currentCustomer = customer
		},
	},

	actions: {
		async getCustomers({ commit }, { merchantId }) {
			const result = await getCustomers({ merchantId })
			commit('setCustomers', { customers: result })
		},

		async getCustomer({ commit }, { id }) {
			const result = await getCustomer({ id })
			commit('setCurrentCustomer', { customer: result })
		},

		async archiveCustomer({ commit }, { id }) {
			const result = await archiveCustomer({ id })
			commit('setCurrentCustomer', { customer: result })
		},

		updateCustomer: async (store, { id, customer }) => {
			await updateCustomer({ id, customer })
		},

		createCustomer: async (store, { customer }) => {
			await createCustomer({ customer })
		},
	},

	getters: {
		customers: state => {
			return state.customers
		},
		customerByMerchantId: state => merchantId => {
			return state.customers.filter(customer => customer.merchantId === merchantId)
		},
		customerName: state => id => {
			return state.customers.find(customer => customer.id === id)
		},
		currentCustomer: state => {
			return state.currentCustomer
		},
	},
}
