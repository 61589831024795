import {
    // Inventory
    createInventoryObject,
    getinventory,
    getInventoryObject,
    updateInventoryObject,
    archiveInventoryObject,
    // Purchase
    createPurchaseOrder,
    getPurchaseOrders,
    getPurchaseOrder,
    updatePurchaseOrder,
    archivePurchaseOrder,
    // Adjustment
    createAdjustmentOrder,
    getAdjustmentOrders,
    getAdjustmentOrder,
    updateAdjustmentOrder,
    archiveAdjustmentOrder,
    // TransferOrder
    createTransferOrder,
    getTransferOrders,
    getTransferOrder,
    updateTransferOrder,
    archiveTransferOrder,
} from 'gox-sdk'

export const inventoryModule = {
    state: () => ({
        // Inventory
        inventories: [],
        currentInventory: [],
        // Purchase
        purchaseOrders: [],
        currentPurchaseOrder: [],
        // Adjustment
        adjustmentOrders: [],
        currentAdjustmentOrder: [],
        // TransferOrder
        transferOrders: [],
        currentTransferOrder: [],
    }),

    mutations: {
        // Inventory
        setInventories(state, { inventories }) {
            state.inventories = inventories
        },
        setCurrentInventory(state, { inventory }) {
            state.currentInventory = inventory
        },
        // Purchase
        setPurchaseOrders(state, { purchaseOrders }) {
            state.purchaseOrders = purchaseOrders
        },
        setCurrentPurchaseOrder(state, { purchaseOrder }) {
            state.currentPurchaseOrder = purchaseOrder
        },
        // Adjustment
        setAdjustmentOrders(state, { adjustmentOrders }) {
            state.adjustmentOrders = adjustmentOrders
        },
        setCurrentAdjustmentOrder(state, { adjustmentOrder }) {
            state.currentAdjustmentOrder = adjustmentOrder
        },
        // TransferOrder
        setTransferOrders(state, { transferOrders }) {
            state.transferOrders = transferOrders
        },
        setCurrentTransferOrder(state, { transferOrder }) {
            state.currentTransferOrder = transferOrder
        },
    },

    actions: {
        // Inventory
        async getInventory({ commit }, { merchantId }) {
            const result = await getinventory({ merchantId })
            commit('setInventories', { inventories: result })
        },

        async getInventoryObject({ commit }, { id }) {
            const result = await getInventoryObject({ id })
            commit('setCurrentInventory', { inventory: result })
        },

        async archiveInventoryObject({ commit }, { id }) {
            const result = await archiveInventoryObject({ id })
            commit('setCurrentInventory', { inventory: result })
        },

        updateInventoryObject: async (store, { id, inventoryObject }) => {
            await updateInventoryObject({ id, inventoryObject })
        },

        createInventoryObject: async (store, { inventoryObject }) => {
            await createInventoryObject({ inventoryObject })
        },
        // Purchase
        async getPurchaseOrders({ commit }, { merchantId }) {
            const result = await getPurchaseOrders({ merchantId })
            commit('setPurchaseOrders', { purchaseOrders: result })
        },

        async getPurchaseOrder({ commit }, { id }) {
            const result = await getPurchaseOrder({ id })
            commit('setCurrentPurchaseOrder', { purchaseOrder: result })
        },

        async archivePurchaseOrder({ commit }, { id }) {
            const result = await archivePurchaseOrder({ id })
            commit('setCurrentPurchaseOrder', { purchaseOrder: result })
        },

        updatePurchaseOrder: async (store, { id, purchaseOrder }) => {
            await updatePurchaseOrder({ id, purchaseOrder })
        },

        createPurchaseOrder: async (store, { purchaseOrder }) => {
            await createPurchaseOrder({ purchaseOrder })
        },
        // Adjustment
        async getAdjustmentOrders({ commit }, { merchantId }) {
            const result = await getAdjustmentOrders({ merchantId })
            commit('setAdjustmentOrders', { adjustmentOrders: result })
        },

        async getAdjustmentOrder({ commit }, { id }) {
            const result = await getAdjustmentOrder({ id })
            commit('setCurrentAdjustmentOrder', { adjustmentOrder: result })
        },

        async archiveAdjustmentOrder({ commit }, { id }) {
            const result = await archiveAdjustmentOrder({ id })
            commit('setCurrentAdjustmentOrder', { adjustmentOrder: result })
        },

        updateAdjustmentOrder: async (store, { id, adjustmentOrder }) => {
            await updateAdjustmentOrder({ id, adjustmentOrder })
        },

        createAdjustmentOrder: async (store, { adjustmentOrder }) => {
            await createAdjustmentOrder({ adjustmentOrder })
        },
        // TransferOrder
        async getTransferOrders({ commit }, { merchantId }) {
            const result = await getTransferOrders({ merchantId })
            commit('setTransferOrders', { transferOrders: result })
        },

        async getTransferOrder({ commit }, { id }) {
            const result = await getTransferOrder({ id })
            commit('setCurrentTransferOrder', { transferOrder: result })
        },

        async archiveTransferOrder({ commit }, { id }) {
            const result = await archiveTransferOrder({ id })
            commit('setCurrentTransferOrder', { transferOrder: result })
        },

        updateTransferOrder: async (store, { id, transferOrder }) => {
            await updateTransferOrder({ id, transferOrder })
        },

        createTransferOrder: async (store, { transferOrder }) => {
            await createTransferOrder({ transferOrder })
        },
    },

    getters: {
        // Inventory
        inventories: state => {
            return state.inventories
        },

        currentInventory: state => {
            return state.currentInventory
        },
        // Purchase
        purchaseOrders: state => {
            return state.purchaseOrders
        },

        currentPurchaseOrder: state => {
            return state.currentPurchaseOrder
        },
        // Adjustment
        adjustmentOrders: state => {
            return state.adjustmentOrders
        },

        currentAdjustmentOrder: state => {
            return state.currentAdjustmentOrder
        },
        // TransferOrder
        transferOrders: state => {
            return state.transferOrders
        },

        currentTransferOrder: state => {
            return state.currentTransferOrder
        },
    },
}
