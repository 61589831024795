import { createMerchant, getMerchants, getMerchant, updateMerchant, archiveMerchant } from 'gox-sdk'

export const merchantModule = {
    state: () => ({
        merchants: [],
        currentMerchant: [],
    }),

    mutations: {
        setMerchants(state, { merchants }) {
            state.merchants = merchants
        },
        setCurrentMerchant(state, { merchant }) {
            state.currentMerchant = merchant
        },
    },

    actions: {
        async getMerchants({ commit }) {
            const result = await getMerchants()
            commit('setMerchants', { merchants: result })
        },

        async getMerchant({ commit }, { id }) {
            const result = await getMerchant({ id })
            commit('setCurrentMerchant', { merchant: result })
        },

        async archiveMerchant({ commit }, { id }) {
            const result = await archiveMerchant({ id })
            commit('setCurrentMerchant', { merchant: result })
        },

        updateMerchant: async (store, { id, merchant }) => {
            await updateMerchant({ id, merchant })
        },

        createMerchant: async (store, { merchant }) => {
            console.log('create')
            await createMerchant({ merchant })
        },
    },

    getters: {
        merchants: state => {
            return state.merchants
        },

        merchantName: state => id => {
            return Object(state.merchants.find(merchant => merchant.id === id))
        },

        currentMerchant: state => {
            return state.currentMerchant
        },
    },
}
