<template>
	<span class="px-2 py-0.5 rounded-xl leading-1" :class="classes">
		<slot> {{ label }}</slot>
	</span>
</template>

<script>
export default {
	name: 'n-badge',

	props: {
		label: {
			type: String,
			default: 'Badge',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: 'sm',
		},
		color: {
			type: String,
			default: 'primary',
		},
		outline: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			classNameMap: {
				fill: {
					primary: 'bg-primary text-white',
					danger: 'bg-danger text-white',
					warning: 'bg-warning text-white',
					success: 'bg-success text-white',
					light: 'bg-white text-secondary',
					muted: 'bg-light-gray text-black',
					disabled: 'bg-gray text-white',
				},
				outline: {
					primary: 'border-2 border-primary text-primary bg-primary bg-opacity-5',
					danger: 'border-2 border-danger text-danger bg-danger bg-opacity-5',
					warning: 'border-2 border-warning text-warning bg-warning bg-opacity-5',
					success: 'border-2 border-success text-success bg-success bg-opacity-5',
					light: 'border-2 border-white text-primary bg-white bg-opacity-50',
					muted: 'border-2 border-muted text-muted bg-muted bg-opacity-20',
				},
				size: {
					xs: 'text-xs',
					sm: 'text-sm',
					md: 'text-md',
				},
			},
		}
	},
	computed: {
		classes() {
			const result = []
			if (this.disabled) result.push('bg-opacity-50')
			if (this.outline) result.push(this.classNameMap.outline[this.color])
			if (this.color) result.push(this.classNameMap.fill[this.color])
			if (this.size) result.push(this.classNameMap.size[this.size])
			return result.join(' ')
		},
	},

	methods: {
		onClick() {
			this.$emit('onClick')
		},
	},
}
</script>
