<template>
	<input :type="type" :value="modelValue" @input="handleInput($event.target.value)" @change="handleChange($event.target.value)" :placeholder="placeholder" :state="state" autocomplete="none" class="border border-gray outline-none text-base placeholder-black px-2 rounded-sm w-full cursor-not-allowed" :class="classes" ref="input" />
</template>

<script>
export default {
	name: 'n-input',

	props: {
		type: {
			type: String,
			default: 'text',
		},
		block: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: '',
		},
		state: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: [String, Number, Object],
			default() {
				return null
			},
		},
	},

	computed: {
		classes() {
			const result = []
			if (this.block) result.push('w-full')
			if (this.disabled) result.push('bg-opacity-50')
			if (this.state) result.push('border-2 border-danger placeholder-danger')
			return result.join(' ')
		},
	},

	methods: {
		handleInput(modelValue) {
			this.$emit('input', modelValue)
		},
		handleChange(modelValue) {
			this.$emit('change', modelValue)
		},
	},
}
</script>
