<template>
	<div id="app" class="flex whitespace-nowrap">
		<button type="button" class="fixed z-50 bottom-4 right-4 w-16 h-16 rounded-full bg-primary text-white block lg:hidden" @click="showNav = !showNav">
			<span class="sr-only">Open site navigation</span>
			<svg width="24" height="24" fill="none" class="absolute top-1/2 left-1/2 -mt-3 -ml-3 transition duration-300 transform" :class="showNav ? 'opacity-0' : 'opacity-1'">
				<path d="M4 8h16M4 16h16" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
			</svg>
			<svg width="24" height="24" fill="none" class="absolute top-1/2 left-1/2 -mt-3 -ml-3 transition duration-300 transform scale-80" :class="!showNav ? 'opacity-0' : 'opacity-1'">
				<path d="M6 18L18 6M6 6l12 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
			</svg>
		</button>
		<div :class="showNav ? 'flex absolute z-50' : 'hidden'" id="nav" class="lg:flex flex-col divide-y-2 divide-secondary space-y-2 p-5 w-3/4 lg:w-96 bg-light-gray h-screen">
			<div class="flex justify-between items-end pb-3">
				<p class="text-primary text-3xl font-bold">NUBETECH</p>
				<n-badge>sdk-v{{ version }}</n-badge>
			</div>
			<router-link to="/" @click.native="showNav = false" class="text-xl py-3">商戶列表</router-link>
			<router-link to="/location" @click.native="showNav = false" class="text-xl py-3">位置列表</router-link>
			<router-link to="/supplier" @click.native="showNav = false" class="text-xl py-3">供應商列表</router-link>
			<router-link to="/catalog" @click.native="showNav = false" class="text-xl py-3">目錄列表</router-link>
			<router-link to="/inventory" @click.native="showNav = false" class="text-xl py-3">庫存列表</router-link>
			<router-link to="/purchaseOrder" @click.native="showNav = false" class="text-xl py-3">採購列表</router-link>
			<router-link to="/adjustmentOrder" @click.native="showNav = false" class="text-xl py-3">調整列表</router-link>
			<router-link to="/transferOrder" @click.native="showNav = false" class="text-xl py-3">轉倉列表</router-link>
			<router-link to="/customer" @click.native="showNav = false" class="text-xl py-3">會員列表</router-link>
			<router-link to="/saleOrder" @click.native="showNav = false" class="text-xl py-3">銷售訂單列表</router-link>
		</div>
		<div v-if="showNav" class="w-full h-full bg-black bg-opacity-50 absolute z-40"></div>
		<router-view class="w-full h-screen p-5 overflow-hidden" />
	</div>
</template>

<script>
import { version } from 'gox-sdk/package.json'

export default {
	data() {
		return {
			version: version,
			showNav: false,
		}
	},
}
</script>
