<template>
	<div class="grid lg:grid-cols-3 gap-5">
		<div class="lg:col-span-2 h-full w-full overflow-y-auto">
			<n-data-table title="商戶列表" :columns="fields" :data="merchants" actions="view,edit,search,refresh" @view="getMerchant" @edit="archiveMerchant">
				<template v-slot:name="{ row }"> {{ row.name['tc'] }}</template>
				<template v-slot:status="{ row }">
					<n-badge :color="statusName(row.status).color"> {{ statusName(row.status).label }}</n-badge>
				</template>
			</n-data-table>
		</div>
		<div class="lg:col-span-1 space-y-2 text-xl h-full overflow-auto">
			<p class="text-4xl pb-2">{{ merchant.id ? '修改' : '新增' }}商戶</p>
			<div class="flex space-x-2">
				<label for="code">商戶編號</label>
				<n-input id="code" type="text" placeholder="請輸入商戶編號" maxlength="3" v-model="merchant.code" />
			</div>

			<div class="flex space-x-2">
				<label for="name">商戶名稱</label>
				<n-input id="name" type="text" placeholder="請輸入商戶名稱" v-model="merchant.name['tc']" />
			</div>

			<div class="flex space-x-2">
				<label for="status">商戶狀態</label>
				<n-select v-model="merchant.status" :options="statusOptions" :reduce="status => status.value" class="w-full h-full text-base" placeholder="請選擇狀態"></n-select>
			</div>
			<ul v-if="locations.length > 0">
				<p class="text-3xl py-2">分店資料</p>
				<li v-for="(location, index) in locationByMerchantId(merchant.id)" :key="index">- {{ location.name['tc'] }}</li>
			</ul>

			<p v-if="error" class="text-danger">{{ '錯誤訊息:' + error }}</p>

			<div class="flex space-x-2">
				<n-button v-if="merchant.id" @onClick="$router.go(0)" color="danger">返回</n-button>
				<n-button @onClick="merchant.id ? updateMerchant(merchant) : createMerchant(merchant)">{{ merchant.id ? '修改商戶' : '加入商戶' }}</n-button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { merchant } from '@/model/merchant'

export default {
	name: 'Home',
	data() {
		return {
			merchant: merchant(),
			fields: [
				{
					label: '商戶編號',
					prop: 'code',
					sortable: true,
				},
				{
					label: '商戶名稱',
					prop: 'name',
					sortable: true,
				},
				{
					label: '商戶狀態',
					prop: 'status',
					sortable: true,
				},
			],
			error: '',
		}
	},
	computed: {
		...mapState(['statusOptions']),
		...mapGetters([
			// merchant
			'merchants',
			'currentMerchant',
			// location
			'locations',
			'locationByMerchantId',
			// state
			'statusName',
		]),
	},
	methods: {
		async createMerchant(data) {
			try {
				await this.$store.dispatch('createMerchant', { merchant: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async getMerchant(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('getMerchant', { id })
				this.merchant = this.currentMerchant
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async archiveMerchant(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('archiveMerchant', { id })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async updateMerchant(data) {
			try {
				this.error = ''
				await this.$store.dispatch('updateMerchant', { id: data.id, merchant: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
	},
}
</script>
