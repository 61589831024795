var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"-my-2 py-2 overflow-x-auto sm:-mx-4 sm:px-4 lg:-mx-4 lg:px-4"},[_c('div',{staticClass:"align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border-b border-light-gray"},[_c('table',{staticClass:"min-w-full"},[_c('thead',{staticClass:"bg-secondary"},[_c('tr',_vm._l((_vm.columns),function(column){return _c('th',{key:column.prop,staticClass:"px-6 py-3 border-b border-muted bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",style:(column.getStyles())},[_c('div',{staticClass:"flex items-center th-cell",class:{
								'cursor-pointer select-none': column.sortable,
								'justify-end': column.align === 'right',
								'justify-start': column.align === 'left',
								'justify-center': column.align === 'center',
							},on:{"click":function($event){return _vm.toggleSort(column)}}},[_c('ColumnHeaderRenderer',{attrs:{"column":column}}),(column.sortable)?_c('div',{staticClass:"flex flex-col ml-1"},[_c('ChevronUpIcon',{staticClass:"w-3 h-3 -mb-1",class:{ 'text-white': column.sortDirection === 'asc' },attrs:{"stroke-width":column.sortDirection === 'asc' ? 5 : 3}}),_c('ChevronDownIcon',{staticClass:"w-3 h-3",class:{ 'text-white': column.sortDirection === 'desc' },attrs:{"stroke-width":column.sortDirection === 'desc' ? 5 : 3}})],1):_vm._e()],1)])}),0)]),_vm._t("default"),_c('tbody',{staticClass:"bg-white"},[_vm._l((_vm.tableData),function(row,index){return _c('tr',{key:row[_vm.rowKey] || index,on:{"click":function($event){return _vm.$emit('onClick')}}},_vm._l((_vm.columns),function(column){return _c('td',{key:column.prop,staticClass:"px-6 py-4 whitespace-no-wrap border-b border-light-gray text-gray-700 td-cell",class:{
							'text-right': column.align === 'right',
							'text-left': column.align === 'left',
							'text-center': column.align === 'center',
						},style:(column.getStyles())},[_c('ColumnRenderer',{attrs:{"row":row,"index":index,"column":column}})],1)}),0)}),((_vm.data.length === 0 && !_vm.loading) || (_vm.data.length === 0 && _vm.loading))?_c('tr',{key:"empty"},[_c('td',{staticClass:"text-center py-5 text-gray-500 text-lg td-cell",attrs:{"colspan":_vm.columns.length}},[_vm._t("empty",function(){return [(!_vm.loading)?_c('span',[_vm._v("No Data")]):(_vm.data.length === 0 && _vm.loading)?_c('span',[_c('svg',{staticClass:"animate-spin -ml-1 mr-3 h-5 w-5 text-white",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})])]):_vm._e()]})],2)]):_vm._e()],2)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }