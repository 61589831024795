<template>
	<div class="overflow-auto w-full text-lg">
		<div v-if="showAction('search') || showAction('refresh') || hasTitle" class="w-full flex flex-wrap items-center mb-4" :class="{ 'justify-end': !hasTitle, 'justify-between': hasTitle }">
			<div>
				<slot name="header-left">
					<h3 class="text-4xl tracking-wider">{{ title }}</h3>
				</slot>
			</div>
			<div class="flex flex-col space-y-2 w-full">
				<div class="flex space-x-2">
					<label for="">選擇顯示頁數:</label>
					<n-select v-model="perPage" :clearable="false" :options="perPageOptions" @input="refresh" class="w-full h-full text-base" placeholder="請選擇顯示數量"></n-select>
				</div>

				<div class="flex space-x-2">
					<label for="">搜尋</label>
					<input type="text" class="border border-muted rounded px-2 w-full" v-if="showAction('search')" v-model="filters.search" :placeholder="'搜尋'" @input="refresh" />
					<div v-if="showAction('refresh')" class="ml-2">
						<n-button @onClick="refresh" size="sm">
							<RefreshCwIcon class="w-5 h-5 text-white" />
						</n-button>
					</div>
				</div>

				<div v-if="showAction('add')" class="ml-2">
					<n-button variant="primary" @click="$emit('add', $event)" size="sm">
						<PlusIcon class="w-5 h-5 text-white" />
					</n-button>
				</div>
			</div>
		</div>
		<n-table :data="displayedTableData" :local-sort="url === ''" :loading="loading" v-bind="$attrs" v-on="$listeners" @sort="onSort">
			<n-table-col v-for="(column, index) in columns" :key="column.prop || index" v-bind="column">
				<template v-slot="{ row }">
					<slot :name="column.prop" :row="row">
						<template v-if="column.component">
							<component :is="column.component" :column="column" :row="row"></component>
						</template>
						<div v-else class="truncate" @click="$emit('view', row, index)">{{ get(row, column.prop) || '- -' }}</div>
					</slot>
				</template>
			</n-table-col>

			<n-table-col v-if="actions" align="right" v-slot="{ row, index }">
				<div class="flex space-x-2">
					<n-button v-if="showAction('view')" size="sm" @onClick="$emit('view', row, index)">修改</n-button>
					<n-button v-if="showAction('edit')" color="danger" size="sm" @onClick="$emit('edit', row, index)">封存</n-button>
					<n-button v-if="showAction('delete')" variant="danger-link" size="sm" @onClick="onDelete(row, index)">Delete</n-button>
					<slot name="extra-actions"></slot>
				</div>
			</n-table-col>
		</n-table>

		<div class="w-full flex justify-end mt-4">
			<ul class="flex space-x-2 text-lg items-center">
				<li class="text-primary font-bold">
					<a href="#" @click="page = 1" aria-label="First">
						<span aria-hidden="true">&laquo;</span>
					</a>
				</li>
				<li>
					<a href="#" v-if="page != 1" @click="page--" aria-label="Previous">
						<span aria-hidden="true">&lsaquo;</span>
					</a>
				</li>
				<li v-for="pageNumber in pages.slice(page - 1, page + 2)" :key="pageNumber" :class="{ 'text-white font-bold bg-primary rounded-lg px-1 ': page === pageNumber }">
					<a href="#" @click="page = pageNumber">{{ pageNumber }}</a>
				</li>
				<li>
					<a href="#" @click="page++" v-if="page < pages.length" aria-label="Next">
						<span aria-hidden="true">&rsaquo;</span>
					</a>
				</li>
				<li>
					<a href="#" @click="page = pages.length" aria-label="Last">
						<span aria-hidden="true">&raquo;</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
import get from 'lodash/get'
import { SearchIcon, RefreshCwIcon, PlusIcon } from 'vue-feather-icons'
import NButton from '../Button'
import NTable from './Table'
import NTableCol from './TableCol'

export default {
	name: 'n-data-table',
	inheritAttrs: false,
	components: {
		SearchIcon,
		PlusIcon,
		RefreshCwIcon,
		NButton,
		NTable,
		NTableCol,
	},
	props: {
		data: {
			type: Array,
			default: () => [],
		},
		columns: {
			type: Array,
			default: () => [],
		},
		title: String,
		actions: {
			type: String,
			default: '',
		},
		url: {
			type: String,
			default: '',
		},
		urlQuery: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			tableData: [],
			loading: false,
			perPageOptions: [5, 10, 15, 20, 25, 50],
			page: 1,
			perPage: 5,
			pages: [],
			filters: {
				search: '',
				sort: '',
			},
		}
	},
	beforeUpdate() {
		this.tableData.length > 0 ? this.tableData : (this.tableData = this.data)
	},
	computed: {
		actionsArray() {
			return this.actions.split(',').map(action => action.trim().toLowerCase())
		},
		hasTitle() {
			return this.$slots['header-left'] || this.title
		},
		displayedTableData() {
			return this.paginate(this.tableData)
		},
	},
	watch: {
		tableData() {
			this.pages = []
			this.setPages()
		},
	},
	methods: {
		get,
		showAction(action) {
			if (!action) {
				return false
			}
			return this.actionsArray.includes(action)
		},
		setPages() {
			const numberOfPages = Math.ceil(this.tableData.length / this.perPage)
			for (let index = 1; index <= numberOfPages; index++) {
				this.pages.push(index)
			}
		},
		paginate(tableData) {
			let page = this.page
			let perPage = this.perPage
			let from = page * perPage - perPage
			let to = page * perPage
			return tableData.slice(from, to)
		},
		onSort({ prop, direction }) {
			if (direction) {
				this.filters.sort = `${prop}|${direction}`
			} else {
				this.filters.sort = ''
			}
		},
		async refresh() {
			try {
				const fields = []
				this.columns.forEach(column => {
					fields.push(column.prop)
				})
				const result = [
					...this.data.filter(ref => {
						let flag = false
						fields.forEach(field => {
							if (typeof ref[field] === 'object' && !Array.isArray(ref[field]) && ref[field].tc.toUpperCase().indexOf(this.filters.search.toUpperCase()) > -1) return (flag = true)
							if (typeof ref[field] === 'string' && ref[field].toUpperCase().indexOf(this.filters.search.toUpperCase()) > -1) return (flag = true)
						})
						if (flag) return ref
					}),
				]
				return (this.tableData = result)
			} catch (err) {
				console.log(err)
			}
		},
		async onDelete(row, index) {
			try {
				const confirmed = await this.$deleteConfirm({
					title: 'Delete row',
					description: `Are you sure you want to delete this row? The data will be removed on our servers. This action cannot be undone.`,
				})

				if (!confirmed) {
					return
				}

				// Replace with your own delete method
				await axios.delete(`${this.url}/${row.id}`)

				if (index === undefined || index < 0) {
					return
				}
				this.tableData.splice(index, 1)

				alert('Row deleted successfully')
			} catch (err) {
				console.warn(err)
				alert('Could not delete the specified row')
			}
			this.$emit('delete', row)
		},
	},
}
</script>
<style scoped>
.search-input {
	min-width: 250px;
}
</style>
