import { createCatalogObject, getCatalog, getCatalogObject, updateCatalogObject, archiveCatalogObject } from 'gox-sdk'

export const catalogModule = {
    state: () => ({
        catalogs: [],
        currentCatalog: [],
    }),

    mutations: {
        setCatalogs(state, { catalogs }) {
            state.catalogs = catalogs
        },
        setCurrentCatalog(state, { catalog }) {
            state.currentCatalog = catalog
        },
    },

    actions: {
        async getCatalogs({ commit }, { merchantId }) {
            const result = await getCatalog({ merchantId })
            commit('setCatalogs', { catalogs: result })
        },

        async getCatalog({ commit }, { id }) {
            const result = await getCatalogObject({ id })
            commit('setCurrentCatalog', { catalog: result })
        },

        async archiveCatalog({ commit }, { id }) {
            const result = await archiveCatalogObject({ id })
            commit('setCurrentCatalog', { catalog: result })
        },

        updateCatalog: async (store, { id, catalogObject }) => {
            await updateCatalogObject({ id, catalogObject })
        },

        createCatalog: async (store, { catalogObject }) => {
            await createCatalogObject({ catalogObject })
        },
    },

    getters: {
        catalogs: state => {
            return state.catalogs
        },

        catalog: state => type => {
            return state.catalogs.filter(catalogItem => catalogItem.type === type)
        },
        catalogByMerchantId: state => (merchantId, type) => {
            return state.catalogs.filter(!type ? catalogItem => catalogItem.merchantId === merchantId : catalogItem => catalogItem.merchantId === merchantId && catalogItem.type === type)
        },

        catalogItem: state => id => {
            return state.catalogs.find(catalogItem => catalogItem.id === id)
        },

        currentCatalog: state => {
            return state.currentCatalog
        },
    },
}
