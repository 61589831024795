import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/location',
		component: () => import('../views/Location.vue'),
	},
	{
		path: '/supplier',
		component: () => import('../views/Supplier.vue'),
	},
	{
		path: '/Catalog',
		component: () => import('../views/Catalog.vue'),
	},
	{
		path: '/Inventory',
		component: () => import('../views/Inventory.vue'),
	},
	{
		path: '/PurchaseOrder',
		component: () => import('../views/PurchaseOrder.vue'),
	},
	{
		path: '/AdjustmentOrder',
		component: () => import('../views/AdjustmentOrder.vue'),
	},
	{
		path: '/TransferOrder',
		component: () => import('../views/TransferOrder.vue'),
	},
	{
		path: '/customer',
		component: () => import('../views/Customer.vue'),
	},
	{
		path: '/SaleOrder',
		component: () => import('../views/SaleOrder.vue'),
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach((to, from, next) => {
	try {
		store.dispatch('getMerchants')
		store.dispatch('getLocations', { merchantId: null })
		store.dispatch('getCatalogs', { merchantId: null })
		if (to.path.includes('supplier')) store.dispatch('getSuppliers', { merchantId: null })
		if (to.path.includes('inventory')) store.dispatch('getInventory', { merchantId: null })
		if (to.path.includes('purchaseOrder')) store.dispatch('getPurchaseOrders', { merchantId: null }), store.dispatch('getSuppliers', { merchantId: null })
		if (to.path.includes('adjustmentOrder')) store.dispatch('getAdjustmentOrders', { merchantId: null })
		if (to.path.includes('transferOrder')) store.dispatch('getTransferOrders', { merchantId: null })
		if (to.path.includes('customer')) store.dispatch('getCustomers', { merchantId: null })
		if (to.path.includes('saleOrder')) store.dispatch('getCustomers', { merchantId: null }), store.dispatch('getSaleOrders', { merchantId: null })
		next()
	} catch (e) {
		console.error(e)
		store.commit('setLoadingState', { loadingState: false })
		next({ path: '/launch' })
	}
})

export default router
