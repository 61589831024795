import Vue from 'vue'
import Vuex from 'vuex'
import { merchantModule } from './modules/merchantModule'
import { locationModule } from './modules/locationModule'
import { catalogModule } from './modules/catalogModule'
import { inventoryModule } from './modules/inventoryModule'
import { supplierModule } from './modules/supplierModule'
import { customerModule } from './modules/customerModule'
import { saleOrderModule } from './modules/saleOrderModule'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		currentType: '',
		statusOptions: [
			{ label: '停用', value: 'INACTIVE', color: 'warning' },
			{ label: '啟用', value: 'ACTIVE', color: 'success' },
			{ label: '封存', value: 'ARCHIVED', color: 'danger' },
		],
		adjustmentStatusOptions: [
			{ label: '開啟', value: 'OPEN', color: 'success' },
			{ label: '完成', value: 'CLOSED', color: 'warning' },
			{ label: '封存', value: 'VOID', color: 'danger' },
		],
		saleOrderTypeOptions: [
			{ label: '堂食', value: 'DINEIN', color: 'success' },
			{ label: '自取', value: 'PICKUP', color: 'warning' },
			{ label: '外賣', value: 'DELIVERY', color: 'danger' },
		],
	},
	mutations: {},
	actions: {},
	modules: {
		merchant: merchantModule,
		location: locationModule,
		catalog: catalogModule,
		inventory: inventoryModule,
		supplier: supplierModule,
		customer: customerModule,
		saleOrder: saleOrderModule,
	},
	getters: {
		statusName: state => value => {
			return state.statusOptions.find(status => status.value === value)
		},
		adjustmentStatusName: state => value => {
			return state.adjustmentStatusOptions.find(status => status.value === value)
		},
	},
})
